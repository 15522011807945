import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactFullpage from '@fullpage/react-fullpage';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';
import Footer from '../components/footer';
import RichText from '../components/richtext';

const BlogPage = ({ data }) => {
  const blogPage = data.blogPage.edges.map(edge => edge.node);
  const posts = blogPage[0].posts.map(post => post);
  const { title } = blogPage[0];

  const [activeSection, setActiveSection] = useState();
  const afterLoad = (origin, destination) => {
    setActiveSection(destination.index);
  };

  return (
    <>
      <Layout
        current={title}
        dePath="/blog"
        enPath="/en/blog"
        hideNav={activeSection === 1}
        disableUp={activeSection === 0}
        disableDown={activeSection === 0}
        onClickUp={() => window.fullpage_api.moveSectionUp()}
        onClickDown={() => window.fullpage_api.moveSectionDown()}
      >
        <SEO siteTitle={title} />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
          responsiveWidth={1020}
          scrollOverflow
          autoScrolling
          scrollingSpeed={600}
          easingcss3="cubic-bezier(0.700, 0.000, 1.000, 1.000)"
          touchSensitivity="100"
          afterLoad={afterLoad}
          render={() => (
            <div id="fullpage-wrapper">
              <StyledPostsSection className="section light fp-auto-height-responsive">
                <div className="inner">
                  {posts.map((post, i) => (
                    <Link key={i} to={post.slug.current}>
                      <div className="post">
                        <div className="date big">
                          <p>{post.publishedAt}</p>
                        </div>
                        <div className="preview">
                          <Img
                            fluid={post.mainImage.image.asset.fluid}
                            alt={post.mainImage.alt}
                            className="img"
                          />
                          <div className="big centered text">
                            <RichText blocks={post._rawBlogTitle} />
                          </div>
                        </div>
                        <div className="link big">
                          <p>
                            READ
                            <br />
                            MORE
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </StyledPostsSection>
              <Footer />
            </div>
          )}
        />
      </Layout>
    </>
  );
};

const StyledPostsSection = styled.section`
  .inner {
    padding: var(--spacing-XL) var(--spacing-XXS) var(--spacing-S)
      var(--spacing-XS);

    .post {
      padding: var(--spacing-XS) 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date,
      .link {
        flex: 1;
      }

      .link {
        margin-right: var(--spacing-XXS);
        text-align: right;
      }

      .preview {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 2;

        .text {
          position: absolute;
          pointer-events: none;
        }

        .img {
          width: calc(var(--fontsize-1) * 4.33);

          &:hover {
            filter: blur(4px);
            opacity: 0.66;
          }
        }
      }

      .link {
        text-decoration: underline;
      }
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-L) var(--spacing-XXS);

      .post {
        .date,
        .link {
          font-size: var(--fontsize-2);
        }

        .link {
          margin-right: 0;
        }
      }
    }
  }
`;

export const query = graphql`
  query Blog {
    blogPage: allSanityBlogPage {
      edges {
        node {
          title
          posts {
            _rawBlogTitle
            slug {
              current
            }
            mainImage {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            publishedAt
          }
        }
      }
    }
  }
`;

BlogPage.propTypes = {
  data: PropTypes.object,
};

export default localize(BlogPage);
